.works-item {
    margin: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 387.67px;
    border-radius: 5px;
    flex-grow: 0;
    z-index: 3;
}

.works-image {
    width: 307.67px;
    height: 205px;
    margin: 40px 40px 0px 40px;
}

.worksname-title {
    font-size: 36px;
    margin: 10px 0 0 40px;
    font-weight: 700;
}

.works-description {
    font-size: 20px;
    margin: 20px 40px 0 40px;
    font-weight: 500;
}

.works-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 40px 40px 40px;
    gap: 16px;
}

/* App Store の画像サイズ */
.appstore-image {
    width: 145.83px;
    height: 46.5px;
}

  /* Google Play の画像サイズ */
.googleplay-image {
    width: 145.83px;
    height: 46.5px;
}

@media (max-width: 768px) {
    .works-item {
        margin: 20px;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        width: 280px;
        border-radius: 5px;
        flex-grow: 0;
        z-index: 3;
    }

    .works-image{
        width: 240px;
        height: auto;
        margin: 20px 20px 0px 20px;
    }

    .worksname-title{
        font-size: 36px;
    }

    .works-description{
        font-size: 16px;
    }

    .works-buttons{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px 40px 40px 40px;
        gap: 16px;
    }
}