.business-content {
  padding: 20px;
  flex: 1;
  margin-right: 40px;
  margin-left: 40px;
}

.business-image {
  background-size: cover;
  background-position: center;
  width: 566px;
  height: 571px;
  border-radius: 5px;
}

.business-item {
  display: flex;
  align-items: stretch;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto 80px;
  max-width: 100%;
  padding: 0;
  width: 100%;
  border-radius: 5px;
}

.business-content-title{
  font-size: 36px;
  font-weight: 700;
  line-height: 52.13px;
  letter-spacing: 0.15em;
  text-align: left;
}

.business-introduction{
  font-size: 20px;
  font-weight: 500;
}

.business-item.reversed {
  flex-direction: row-reverse; /* コンテンツと画像の順序を逆にする */
}

.business-button {
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 2px;
  display: inline-block;
  margin: 48px 0 0 0;
  padding: 28px 44px;
  border: 2px solid black;
  cursor: pointer;
  color: black;
  background-color: white;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.business-button:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .business-content {
    padding: 20px;
    flex: 1;
    margin-right: 5px;
    margin-left: 5px;
  }

  .business-image {
    background-size: cover;
    background-position: center;
    width: 280px;
    height: 280px;
    border-radius: 5px;
  }

  .business-item {
    display: block;
    align-items: normal;
    padding: 0;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 280px;
    border-radius: 5px;
    flex-grow: 0;
    z-index: 3;
  }

  .business-content-title {
    line-height: normal;
    text-align: center;
    font-size: 24px;
    margin: 10px;
    font-weight: 700;
  }

  .business-introduction {
    text-align: center;
    font-size: 16px;
    margin: 20px 10px 20px 10px;
    font-weight: 500;
  }

  .business-item.reversed {
    flex-direction: row;
  }

  .business-button {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 2px;
    display: block;
    margin: 28px auto;
    padding: 28px 44px;
    border: 2px solid black;
    cursor: pointer;
    color: black;
    background-color: white;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease;
  }
}