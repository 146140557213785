.works-container {
    position: relative;
    z-index: 2;
    overflow: hidden;
}

.works-grid {
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 80px;
    align-items: flex-start;
    z-index: 3;
}

.works-title{
    display: flex;
    gap: 48px;
    align-items: center;
    font-weight: 700;
    letter-spacing: 0.15em;
}

.works-title-en {
    font-size: 72px;
    line-height: 104.26px;
    letter-spacing: 0.15em;
}

.works-title-jp {
    font-size: 20px;
    padding-top: 12px;
    line-height: 28.96px;
    text-align: left;
}

@media (max-width: 1024px) {
    .works-title {
        display: flex;
        gap: 48px;
        align-items: center;
    }

    .works-title-jp {
        font-size: 16px;
        font-weight: bold;
        padding-top: 12px;
    }

    .works-title-en {
        font-size: 36px;
    }
}

@media (max-width: 768px) {
    .works-title {
        gap: 12px;
    }

    .works-title-en {
        font-size: 36px;
    }

    .works-title-jp {
        font-size: 14px;
        padding-top: 8px;
        margin: 0px;
    }

    .works-grid {
        gap: 20px;
    }
}