.works-background-container {
    position: relative;
    width: 100%;
    height: auto;
    z-index: 1;
}

.background-object1 {
    width: 700px;
    height: 700px;
    position: absolute;
    right: -400px;
    top: -200px;
    z-index: 1;
}

.background-object2 {
    width: 228px;
    height: 156.5px;
    position: absolute;
    right: -350px;
    top: 150px;
    z-index: 1;
}

@media (max-width: 768px) {
    .background-object1 {
        width: 350px;
        height: 350px;
        right: -130px;
        top: -50px;
    }

    .background-object2 {
        width: 114px;
        height: auto;
        right: -40px;
        top: 120px;
    }
}