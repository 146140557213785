.business-section {
    position: relative;
    padding: 120px 0;
    z-index: 3;
}

.business-background {
    background: linear-gradient(109deg, #B4FEFC 0%, #FFDAFC 100%);
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    z-index: 1;
}

.business-background::after {
    content: "";
    display: table;
    clear: both;
    padding-bottom: 45px;
}

.business {
    padding: 0 11.57%;
}

.business-title {
    display: flex;
    gap: 48px;
    align-items: center;
    font-weight: 700;
    letter-spacing: 0.15em;
}

.business-title-en {
    font-size: 72px;
    line-height: 104.26px;
    letter-spacing: 0.15em;
}

.business-title-jp {
    font-size: 20px;
    padding-top: 12px;
    line-height: 28.96px;
    text-align: left;
}

@media (max-width: 1024px) {
    .business-title {
        display: flex;
        gap: 48px;
        align-items: center;
    }

    .business-title-jp {
        font-size: 16px;
        font-weight: bold;
        padding-top: 12px;
    }

    .business-title-en{
        font-size: 36px;
    }
}

@media (max-width: 768px) {
    .business-title {
        gap: 12px;
    }

    .business-title-en{
        font-size: 36px;
    }

    .business-title-jp {
        font-size: 14px;
        padding-top: 8px;
        margin: 0px;
    }
}