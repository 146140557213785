.news-subitem {
    position: relative;
    padding: 150px 0;
    z-index: 3;
}

.news-title {
    display: flex;
    gap: 48px;
    align-items: center;
    font-weight: 700;
    letter-spacing: 0.15em;
}

.news-title-en{
    font-size: 72px;
    line-height: 104.26px;
    letter-spacing: 0.15em;
}

.news-title-jp {
    font-size: 20px;
    padding-top: 12px;
    line-height: 28.96px;
    text-align: left;
}

.grey-divider {
    height: 2px;
    background-color: #ECEDED;
    width: 100%;
    z-index: 3;
}

/* レスポンシブデザイン */
@media (max-width: 1024px) {

    .news-subitem {
        position: relative;
        padding: 40px 0;
        z-index: 3;
    }

    .news-title {
        display: flex;
        gap: 48px;
        align-items: center;
    }

    .news-title-jp {
        font-size: 16px;
        font-weight: bold;
        padding-top: 12px;
    }

    .news-title-en{
        font-size: 36px;
    }
}

@media (max-width: 768px) {
    .news-title {
        gap: 12px;
    }

    .news-title-en {
        font-size: 36px;
    }

    .news-title-jp {
        font-size: 14px;
        padding-top: 8px;
        margin: 0px;
    }
}