.contact-subitem {
    position: relative;
    padding: 120px 0;
    z-index: 3;
}

.contact-background {
    background: linear-gradient(109deg, #A5FF94 0%, #B4FEFC 50%, #FFDAFC 100%);
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    z-index: 1;
}

.contact-background::after {
    content: "";
    display: table;
    clear: both;
    padding-bottom: 45px;
}

.contact-title {
    display: flex;
    gap: 48px;
    align-items: center;
    font-weight: 700;
    letter-spacing: 0.15em;
}

.contact-title-jp {
    font-size: 20px;
    padding-top: 12px;
    line-height: 28.96px;
    text-align: left;
}

.contact-title-en {
    font-size: 72px;
    line-height: 104.26px;
    letter-spacing: 0.15em;
}

.contact-text {
    font-size: 20px;
    font-weight: 500;
    z-index: 2;
}

.contact {
    padding: 0 11.57%;
}

.contact > * {
    margin-top: 40px;
    margin-bottom: 40px;
}

.contact > *:first-child {
    margin-top: 0;
}

.contact > *:last-child {
    margin-bottom: 0;
}

@keyframes rainbow_button {
    0% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

.custom-button {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 2px;
    display: inline-block;
    padding: 28px 80px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: black;
    background-color: white;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    transition: color 0.3s,transform 0.3s ease;
    margin-top: 80px;
    margin-bottom: 80px;
}

.custom-button:hover {
    transform: scale(1.05);
    background-image: linear-gradient(109deg, #FFC1D2 0%, #f1a3ff 30%, #fea2e4 60%, #FFC1D2 100%); /* ホバー時のグラデーション */
    background-size: 200% 100%;
    animation: rainbow 3s infinite linear;
    color: white;
}

@media (max-width: 1024px) {
    .contact-title {
        display: flex;
        gap: 48px;
        align-items: center;
    }

    .contact-title-jp {
        font-size: 16px;
        font-weight: bold;
        padding-top: 12px;
    }

    .contact-title-en {
        font-size: 36px;
    }
}

@media (max-width: 768px) {
    .contact-title {
        gap: 12px;
    }

    .contact-title-en {
        font-size: 36px;
    }

    .contact-title-jp {
        font-size: 14px;
        padding-top: 8px;
        margin: 0px;
    }

    .contact-text {
        font-size: 16px;
        font-weight: 500;
        z-index: 2;
    }

    .custom-button {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .contact>* {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}