.NewsBackground {
    position: absolute;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
    width: 329px;
    height: 329px;
    top: 1054px;
    left: -90px;
    gap: 0px;
    opacity: 0px;
}

@media (max-width: 768px) {
    .NewsBackground{
        width: 150px;
        height: 150px;
        top: 700px;
        left: -90px;
    }
}