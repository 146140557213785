.news {
    display: flex;
    padding: 20px 0;
    transition: all 0.3s ease;
    font-weight: 500;
}

.news p {
    text-decoration: none;
}

.news-day {
    color: #B4B4B5;
    padding: 0 40px 0 20px;
    font-size: 20px;
    font-family: Zen Kaku Gothic New;
    font-weight: 700;
    line-height: 28.96px;
    letter-spacing: 0.15em;
    text-align: left;
}

.news-text {
    color: #000;
    font-size: 20px;
}

.grey-divider {
    height: 2px;
    background-color: #ECEDED;
    width: 100%;
    z-index: 3;
}

.news-link {
    text-decoration: none; /* リンクの下線を消す */
}

@media (max-width: 768px){
    .news p{
        font-size: 14px;
    }

    .news-day{
        font-size: 14px;
    }
}