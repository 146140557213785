.member {
    position: relative;
    padding: 120px 0;
    z-index: 3;
    font-family: 'Zen Kaku Gothic New', sans-serif;
    font-weight: 500;
}

.member-title {
    display: flex;
    gap: 48px;
    align-items: center;
    font-weight: 700;
    letter-spacing: 0.15em;
}

.member-title-en{
    font-size: 72px;
    line-height: 104.26px;
    font-weight: 700;
    line-height: 104.26px;
    letter-spacing: 0.15em;
}

.member-title-jp {
    font-size: 20px;
    padding-top: 12px;
    line-height: 28.96px;
    text-align: left;
}

.member-item {
    gap: 20px;
    padding-bottom: 40px;
}

.member-item .member-name {
    font-size: 24px;
    font-weight: bold;
}
@media (max-width: 1024px) {
    .member-title {
        display: flex;
        gap: 48px;
        align-items: center;
    }

    .member-title-jp {
        font-size: 16px;
        font-weight: bold;
        padding-top: 12px;
    }

    .member-title-en {
        font-size: 36px;
    }
}

@media (max-width: 768px) {
    .member-title {
        gap: 12px;
    }

    .member-title-en {
        font-size: 36px;
        line-height: 36px;
    }

    .member-title-jp {
        font-size: 14px;
        margin: 0px;
    }

    .member{
        padding: 0px 0;
    }

    .member-item {
        padding-bottom: 20px;
    }

    .member-item .member-name {
        font-size: 20px;
        font-weight: bold;
    }
}