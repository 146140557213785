.company {
    position: relative;
    padding: 120px 0;
    z-index: 3;
    font-family: 'Zen Kaku Gothic New', sans-serif;
    font-weight: 500;
}

.company-title {
    display: flex;
    gap: 48px;
    align-items: center;
    font-weight: 700;
    letter-spacing: 0.15em;
}

.company-title-en{
    font-size: 72px;
    line-height: 104.26px;
}

.company-title-jp {
    font-size: 20px;
    padding-top: 12px;
    line-height: 28.96px;
    text-align: left;
}

.company-info {
    padding-bottom: 40px;
}

.company-item {
    display: flex;
    gap: 48px;
    align-items: center;
    padding-bottom: 20px;
    font-size: 20px;
}

.company-item .company-theme {
    display: flex;
    width: 80px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    background: #F5F5F5;
    font-size: 12px;
    font-weight: 700;
}

.company-info .company-item .company-theme {
    width: 80px;
    flex-shrink: 0;
}

@media (max-width: 1024px) {
    .company-title {
        display: flex;
        gap: 48px;
        align-items: center;
    }

    .company-title-jp {
        font-size: 16px;
        font-weight: bold;
        padding-top: 12px;
    }

    .company-title-en {
        font-size: 36px;
    }
}

@media (max-width: 768px) {
    .company{
        padding-top: 50px;
        padding-bottom: 0px;
    }

    .company-title {
        gap: 12px;
    }

    .company-title-en {
        font-size: 36px;
        line-height: 36px;
    }

    .company-title-jp {
        font-size: 14px;
        margin: 0px;
    }

    .company-item {
        gap: 30px;
        padding-bottom: 20px;
    }

    .company-item .company-theme {
        display: flex;
        width: 80px;
        padding: 10px 16px;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        background: #F5F5F5;
        font-size: 12px;
        font-weight: 700;
    }

    .company-info .company-item .company-theme {
        width: 80px;
        flex-shrink: 0;
    }
}