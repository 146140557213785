.background-object10{
    width: 300px;
    height: 300px;
    position: absolute;
    left: -100px;
    top: 70px;
}

.background-object8 {
    width: 150px;
    height: 80px;
    position: absolute;
    right: 200px;
    top: 800px;
    z-index: 1;
}

@media (max-width: 768px) {
    .background-object10 {
        width: 150px;
        height: 150px;
        left: -60px;
        top: 70px;
    }

    .background-object8 {
        width: 100px;
        height: auto;
        right: 0px;
        top: 1020px;
        z-index: 1;
    }
}