header {
    height: 120px;
    width: 100%;
    top: 0;
    left: 0;
    padding: 0 32px;
    box-sizing: border-box;
    cursor: default;
    background-color: rgba(255, 255, 255, 0.3);
    color: black;
    position: fixed;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    backdrop-filter: blur(3px);
    z-index: 5;
}

header .headline {
    top: 0;
}

.headline img {
    height: 40px;
    width: 216.47px;
    vertical-align: middle;
}

.nav-list {
    list-style: none;
    display: flex;
}

.nav-list-item {
    list-style: none;
    margin: 0 0 0 60px;
}

.nav-list-item a {
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
    color: black;
    background-image: linear-gradient(90deg, #FFD1DC, #FFDADA, #FFEBB5, #DAFFD6, #D1EFFF, #E0BBFF, #FFC8E6);
    background-size: 200% auto;
    -webkit-background-clip: text;
    background-clip: text;
    transition: background 3s ease;
}

.nav-list-item a:hover {
    color: transparent;
    animation: rainbow 3s infinite;
    transition : 0.5s;
}

@keyframes rainbow {
    0% { background-position: 100% 0; }
    100% { background-position: -100% 0; }
}

.hamburger {
    display: none;
    cursor: pointer;
    width: 32px;
    height: 16px;
    position: relative;
    padding-bottom: 10px;
}

.bar1, .bar2, .bar3 {
    width: 100%;
    height: 4px;
    background-color: black;
    margin: 6px 0;
    transition: 0.4s;
}

/* アニメーションのCSS */
.hamburger.change .bar1 {
    transform: rotate(-45deg) translate(-9px, 6px);
}

.hamburger.change .bar2 {
    opacity: 0;
}

.hamburger.change .bar3 {
    transform: rotate(45deg) translate(-9px, -6px);
}

#dummy-news , #dummy-business , #dummy-contact, #dummy-company, #dummy-MEMBER{
    height: 120px;
    margin-top: -120px;
}

/* レスポンシブデザイン */
@media (max-width: 768px) {
    header {
        padding: 0px 16px;
        backdrop-filter: none;
    }

    .header-margin {
        padding-top: 70px;
    }

    .hamburger {
        display: block;
        z-index: 5;
    }

    .headline img {
        height: 32px;
        width: auto;
        object-fit: contain;
    }

    .nav-list {
        display: none;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100vh;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(3px);
        opacity: 0;
        transition: opacity 0.5s ease;
        z-index: 4;
    }

    .nav-list.active {
        margin: 0px;
        padding: 0px;
        display: flex;
        opacity: 1;
    }

    .nav-list a {
        font-size: 20px;
    }

    .nav-list-item {
        margin: 20px 0;
    }
}