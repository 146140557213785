.top-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.background-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    animation-duration: 2.5s;
    animation-timing-function: cubic-bezier(0.2, 1, 0.2, 1);
    animation-fill-mode: forwards;
    position: absolute;
    z-index: 1;
    overflow: hidden;
}

.image-1 {
    width: 502.66px;
    height: 504.51px;
    background: url('../../images/objects/object1.png') center/contain no-repeat;
    animation-name: moveImage1;
    z-index: 1;
}

.image-2 {
    width: 228px;
    height: 156.5px;
    background: url('../../images/objects/object2.png') center/contain no-repeat;
    animation-name: moveImage2;
    animation-duration: 3.5s;
    z-index: 2;
}

.image-3 {
    width: 228px;
    height: 120px;
    background: url('../../images/objects/object3.png') center/contain no-repeat;
    animation-name: moveImage3;
    animation-duration: 2.0s;
}

.image-4 {
    width: 67px;
    height: 67px;
    background: url('../../images/objects/object4.png') center/contain no-repeat;
    animation-name: moveImage4;
    animation-duration: 1.5s;
}

.image-5 {
    width: 271px;
    height: 272px;
    background: url('../../images/objects/object5.png') center/contain no-repeat;
    animation-name: moveImage5;
    z-index: 1;
    animation-duration: 5.5s;
}

.image-6 {
    width: 149px;
    height: 149px;
    background: url('../../images/objects/object6.png') center/contain no-repeat;
    animation-name: moveImage6;
    animation-duration: 3.5s;
}

.image-7 {
    width: 104px;
    height: 104px;
    background: url('../../images/objects/object7.png') center/contain no-repeat;
    animation-name: moveImage7;
}

.image-8 {
    width: 228px;
    height: 120px;
    background: url('../../images/objects/object8.png') center/contain no-repeat;
    animation-name: moveImage8;
}

.image-9 {
    width: 329px;
    height: 329px;
    background: url('../../images/objects/object9.png') center/contain no-repeat;
    animation-name: moveImage9;
    animation-duration: 3.5s;
}

.image-10 {
    width: 258.75px;
    height: 258.75px;
    background: url('../../images/objects/object10.png') center/contain no-repeat;
    animation-name: moveImage10;
    animation-duration: 4s;
}



@keyframes moveImage1 {
    100% {
        top: 3%;
        left: 3%;
        transform: translate(-40%, -30%);
        opacity: 1;
    }
}

@keyframes moveImage2 {
    100% {
        top: 3%;
        left: -3%;
        transform: translate(100%, 50%);
        opacity: 1;
    }
}

@keyframes moveImage3 {
    100% {
        top: 40%;
        left: 3%;
        transform: translate(0%, 400%);
        opacity: 1;
    }
}

@keyframes moveImage4 {
    100% {
        top: 5%;
        left: 5%;
        transform: translate(800%, -10%);
        opacity: 1;
    }
}

@keyframes moveImage5 {
    100% {
        top: 3%;
        left: 4%;
        transform: translate(180%, 200%);
        opacity: 1;
    }
}

@keyframes moveImage6 {
    100% {
        top: 6%;
        right: 5%;
        transform: translate(200%, 600%);
        opacity: 1;
    }
}

@keyframes moveImage7 {
    100% {
        top: 3%;
        right: 1%;
        transform: translate(400%, 400%);
        opacity: 1;
    }
}

@keyframes moveImage8 {
    100% {
        top: 15%;
        left: 40%;
        transform: translate(300%, 100%);
        opacity: 1;
    }
}

@keyframes moveImage9 {
    100% {
        top: 5%;
        right: 5%;
        transform: translate(200%, 0%);
        opacity: 1;
    }
}

@keyframes moveImage10 {
    100% {
        top: 41%;
        left: 34%;
        transform: translate(380%, 180%);
        opacity: 1;
    }
}

.text-container {
    font-size: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    position: relative;
    font-weight: 700;
    font-size: 72px;/*会社のビジョンのフォントサイズ変更*/
    letter-spacing: 10.8px;
    line-height: 2;
    z-index: 2;
}

.letter {
    opacity: 0;
    transform: translateX(100%);
    color: pink;
    animation: slideIn 0.5s forwards 0.5s;
    display: inline-block;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 0 5px;
}

.break-line {
    display: block;
    width: 100%;
}

@keyframes slideIn {
    0% {
        transform: translateX(100%);
        color: pink;
        opacity: 0;
    }
    50% {
        color: pink;
    }
    100% {
        transform: translateX(0);
        color: black;
        opacity: 1;
    }
}

/* レスポンシブデザイン */

@media (max-width: 1024px) {
    .text-container {
        font-size: 40px;
        overflow: hidden;
    }
}

@media (max-width: 768px) {
    .text-container {
        font-size: 30px;
        overflow: hidden;
    }

    .image-1 {
        width: 300px;
        height: 300px;
    }

    .image-2 {
        width: 145.6px;
        height: 100px;
    }

    .image-3 {
        width: 190px;
        height: 100px;
    }

    .image-4 {
        width: 30px;
        height: 30px;
    }

    .image-5 {
        width: 110px;
        height: 110px;
    }

    .image-6 {
        display: none;
    }

    .image-7 {
        display: none;
    }

    .image-8 {
        display: none;
    }

    .image-9 {
        width: 150px;
        height: 150px;
    }

    .image-10 {
        display: none;
    }

    @keyframes moveImage1 {
        100% {
            top: 3%;
            left: 3%;
            transform: translate(-40%, -40%);
            opacity: 1;
        }
    }

    @keyframes moveImage2 {
        100% {
            top: 3%;
            left: -3%;
            transform: translate(30%, 50%);
            opacity: 1;
        }
    }

    @keyframes moveImage3 {
        100% {
            top: 40%;
            left: 3%;
            transform: translate(-70%, 280%);
            opacity: 1;
        }
    }

    @keyframes moveImage4 {
        100% {
            top: 5%;
            left: 5%;
            transform: translate(1000%, -10%);
            opacity: 1;
        }
    }

    @keyframes moveImage5 {
        100% {
            top: 3%;
            left: 4%;
            transform: translate(180%, 200%);
            opacity: 1;
        }
    }

    @keyframes moveImage9 {
        100% {
            top: 5%;
            right: 5%;
            transform: translate(20%, 300%);
            opacity: 1;
        }
    }
}