.footer {
    display: flex;
    padding-bottom: 40px;
}

.footer img {
    height: 40px;
}

.footer-list {
    list-style: none;
    margin-top: 10px;
    margin-left: 25%;
}

.footer-list a:hover {
    color: transparent;
    animation: rainbow 3s infinite;
    transition : 0.5s;
}

.footer-list a {
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
    color: black;
    background-image: linear-gradient(90deg, #FFD1DC, #FFDADA, #FFEBB5, #DAFFD6, #D1EFFF, #E0BBFF, #FFC8E6);
    background-size: 200% auto;
    -webkit-background-clip: text;
    background-clip: text;
    transition: background 3s ease;
}

.footer-list-item {
    margin-bottom: 40px;
}

@media (max-width: 768px) {
    .footer {
        flex-flow: column;
        padding-bottom: 0px;
    }

    .footer img {
    text-align: left;
    margin-right: auto;
    object-fit: contain;
    }

    .footer-list {
        list-style: none;
        padding: 0%;
        margin-top: 40px;
        margin-left: 0%;
    }

    .footer-list a:hover {
        color: transparent;
        animation: rainbow 3s infinite;
        transition: 0.5s;
    }

    .footer-list a {
        font-size: 14px;
    }

    .footer-list-item {
        margin-bottom: 28px;
    }
}