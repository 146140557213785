.background-object6{
    width: 350px;
    height: 350px;
    position: absolute;
    right: -60px;
    top: 800px;
    z-index: 2;
}

.background-object7{
    width: 100px;
    height: 100px;
    position: absolute;
    left: 50px;
    top: 1700px;
    z-index: 1;
}

@media (max-width: 768px) {
    .background-object6 {
        width: 180px;
        height: auto;
        right: -30px;
        top: 1050px;
        z-index: 2;
    }

    .background-object7 {
        width: 80px;
        height: auto;
        left: -30px;
        top: 1560px;
        z-index: 1;
    }
}